import React from 'react';
import FooterV2 from './FooterV2';
import NavBarLightV2 from './NavBarLightV2';
import CareersContent from './CareersContent';

const Careers = () => {
  return (
    <>
    <NavBarLightV2
    color="#eeeeee" />
    <CareersContent />
    <FooterV2 />
    </>
  )
}

export default Careers
