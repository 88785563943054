import React from 'react'


const Globe = () => {
  return (
    <>
     
      
    </>
  )
}

export default Globe